.details {
    margin-bottom: .5em;
}

.card {
    background: var(--surface-card);
    padding: 1em 2rem;
    border-radius: 10px;
}

.p-card-title {
    padding-bottom: 1em;
}

.p-card-content {
    padding: 0;
}

.p-button {
    width: 100%
}

.logo {
    max-width: 500px;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-center {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; 
    align-items: center;
}

.successMessage {
    width: 100%;
}

.error .p-card-title {
    color: #EF4444
}